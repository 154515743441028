import React from "react"
import styles from "./stylesheets/mediaCenter.module.scss"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import cx from "classnames"

export default function MediaCenter({ componentData, articlesData }) {
  const headline = componentData.headline && componentData.headline[0].text
  const subheader = componentData.subhead && componentData.subhead[0].text

  return (
    <div className={cx(styles.topLevelContainer, "py-5 px-3 px-md-5 mb-5")}>
      <div className="mb-2 mb-md-4 pb-2">
        <h1 className={cx(styles.title, "text-secondary")}>{headline}</h1>
        <h2 className={styles.subheader}>{subheader}</h2>
      </div>
      <section className="d-flex flex-column">
        {articlesData.map((articleData) => {
          const articleTitle =
            articleData.article_title && articleData.article_title[0].text
          const articleDate = articleData.article_date.split("-")

          return (
            <div className="mb-4" key={articleTitle}>
              <div className={styles.articleDate}>
                {`${articleDate[1]}/${articleDate[2]}/${articleDate[0]}`}
              </div>
              <div>
                <OutboundLink
                  href={articleData.article_link_destination.url}
                  className={styles.articleLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {articleTitle}
                </OutboundLink>
              </div>
            </div>
          )
        })}
      </section>
    </div>
  )
}
