import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import cx from "classnames"
import styles from "./stylesheets/halfAndHalfText.module.scss"

export default function HalfAndHalfText({ leftText, rightText, background }) {
  return (
    <section
      className={cx(
        background === "Gray Tire Treads" && styles.backgroundGray,
        "row py-3 py-md-5",
      )}
    >
      <div
        className={cx(
          styles.richText,
          styles.leftText,
          "col-12 col-md-6 px-md-5",
          "px-3",
        )}
      >
        <RichText render={leftText} linkResolver={linkResolver} />
      </div>
      <div className={cx(styles.richText, "col-12 col-md-6 px-md-5", "px-3")}>
        <RichText render={rightText} linkResolver={linkResolver} />
      </div>
    </section>
  )
}
