import React from "react"
import Imgix from "react-imgix"
import { Button } from "react-bootstrap"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import styles from "./stylesheets/leadershipTeam.module.scss"
import cx from "classnames"

export default function LeadershipTeam({ teamData }) {
  const [expandedRow, setExpandedRow] = React.useState(0)
  const rows = chunk(teamData, 3)

  return (
    <section className={styles.topLevelContainer}>
      <div className="pl-md-5 pt-md-4 my-5 px-3">
        {rows.map((teamRow, index) => {
          const teamMembers = teamRow.map((teamMember) => {
            const avatar = teamMember.picture
            const name = teamMember.name && teamMember.name[0]?.text
            const position = teamMember.position && teamMember.position[0]?.text
            const isExpanded = index === expandedRow

            return (
              <TeamMember
                avatar={avatar}
                name={name}
                position={position}
                key={position}
                bio={teamMember.bio}
                setExpandedRow={() =>
                  isExpanded ? setExpandedRow(null) : setExpandedRow(index)
                }
                isExpanded={isExpanded}
              />
            )
          })
          return (
            <div
              className="d-flex flex-wrap"
              key={index} // eslint-disable-line react/no-array-index-key
            >
              {teamMembers}
            </div>
          )
        })}
      </div>
    </section>
  )
}

function TeamMember({
  avatar,
  name,
  position,
  bio,
  setExpandedRow,
  isExpanded,
}) {
  return (
    <div className="col-12 col-md-4 pl-0" key={position}>
      <div className={cx(styles.titleBlock, "d-flex pb-3 mr-md-5")}>
        <Imgix
          width={150}
          height={150}
          src={avatar.url}
          alt={avatar.alt}
          className={styles.avatar}
        />
        <div className="d-flex flex-column justify-content-center pl-3">
          <h4 className={cx(styles.executiveName, "text-primary")}>{name}</h4>
          <h5 className={styles.executivePosition}>{position}</h5>
        </div>
      </div>

      <div className={cx(styles.memberContainer, "mb-5 mt-1 mr-md-5")}>
        <Button
          className="border-0 bg-transparent text-black px-0 pb-2"
          onClick={() => setExpandedRow()}
        >
          <span className={styles.bioToggle}>
            Read Biography
            <span
              className={cx(
                styles.tryCaret,
                isExpanded && styles.rotate,
                "ml-1",
              )}
            ></span>
          </span>
        </Button>
        {isExpanded && (
          <div className={styles.bioBlock}>
            <RichText render={bio} linkResolver={linkResolver} />
          </div>
        )}
      </div>
    </div>
  )
}

const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}
