import React from "react"
import { RichText } from "prismic-reactjs"
import Imgix from "react-imgix"
import ServiceBlurb from "./serviceBlurb"
import { linkResolver } from "../utils/linkResolver"
import cx from "classnames"
import styles from "./stylesheets/heroWithIconsAndImage.module.scss"

export default function HeroWithIconsAndImage({ iconFields, generalFields }) {
  const imageData = generalFields.hero_image
  const richText = generalFields.left_text

  return (
    <section className={cx("py-2 py-md-3", "row")}>
      <div className={cx(styles.richText, "col-12 col-lg-6 px-md-5", "px-3")}>
        <RichText render={richText} linkResolver={linkResolver} />
        {iconFields.map((blurb) => {
          if (!blurb.text) return null

          return (
            <ServiceBlurb
              key={blurb.icon.url}
              iconData={blurb.icon}
              description={blurb.text}
            />
          )
        })}
      </div>
      <div className="col-12 col-lg-6 px-0 justify-self-center align-self-start pb-2 pt-md-3 mt-md-4">
        <Imgix
          src={imageData.url}
          alt={imageData.alt}
          className={styles.heroImage}
          width={750}
        />
      </div>
    </section>
  )
}
