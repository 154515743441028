import React from "react"
import Imgix from "react-imgix"
import { RichText } from "prismic-reactjs"
import Recaptcha from "react-recaptcha"
import { siteKey } from "../utils/recaptcha"
import { linkResolver } from "../utils/linkResolver"
import ContactBaseFields from "./contactBaseFields"
import ThankYouAlert from "./thankYouAlert"
import styles from "./stylesheets/contactSection.module.scss"
import cx from "classnames"

export default function ContactSection({ contactData }) {
  const [thankYouMode, setThankYouMode] = React.useState(false) // eslint-disable-line no-unused-vars
  const [canSubmit, setCanSubmit] = React.useState(false)
  const path = "/api/submit_salesforce"

  return (
    <div className={cx(styles.topLevelContainer, "py-5 px-3 px-md-5 mb-5")}>
      <div className="mb-2 mb-md-5">
        <h1 className={cx(styles.title, "text-secondary")}>
          Contact United Road
        </h1>
        <h2 className={styles.subheader}>We'd love to hear from you.</h2>
      </div>
      <section className="row">
        <div className="col-12 col-md-6">
          <div className={cx(styles.headerTitle, "w-100 mb-3")}>
            Contact Us by Mail or Phone
          </div>
          <div className={cx(styles.subTitle, "font-weight-bold mb-md-3")}>
            United Road Headquarters
          </div>

          <div className="d-flex align-items-center mb-md-3">
            <div className="mr-3 d-none d-md-flex">
              <Imgix
                src={contactData.address_icon.url}
                alt={contactData.address_icon.alt}
                className={styles.contactIcon}
                height={100}
                width={100}
              />
            </div>
            <div className={styles.addressText}>
              <RichText
                render={contactData.address}
                linkResolver={linkResolver}
              />
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="mr-3 d-none d-md-flex">
              <Imgix
                src={contactData.phone_icon.url}
                alt={contactData.phone_icon.alt}
                className={styles.contactIcon}
                height={100}
                width={100}
              />
            </div>
            <div className={styles.phoneText}>
              <a
                href={`tel:${contactData.phone[0]?.text}`}
                key={contactData.phone[0]?.text}
              >
                <RichText
                  render={contactData.phone}
                  linkResolver={linkResolver}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className={cx(styles.headerTitle, "w-100 mb-3")}>
            Send Us a Message
          </div>
          <div>
            {!thankYouMode && (
              <form action={path} method="POST">
                <ContactBaseFields
                  leadSource={"contact-page-web"}
                  hideAdvanced={true}
                  hideCompany={false}
                  hideNumberOfVehicles={true}
                  optionalEmail={false}
                />

                <div className="mt-3 mb-2">
                  <Recaptcha
                    sitekey={siteKey}
                    render="explicit"
                    verifyCallback={() => setCanSubmit(true)}
                  />
                </div>

                <input
                  type="submit"
                  name="submit"
                  value="Send Message"
                  disabled={!canSubmit}
                  className={cx(
                    styles.submitButton,
                    "btn btn-lg btn-success mr-2 mt-4",
                  )}
                />
              </form>
            )}
            {thankYouMode && <ThankYouAlert />}
          </div>
        </div>
      </section>
    </div>
  )
}
