import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSectionWithImage from "../components/heroSectionWithImage"
import HalfAndHalfText from "../components/halfAndHalfText"
import HeroWithIconsAndImage from "../components/heroWithIconsAndImage"
import ContactSection from "../components/contactSection"
import FullWidthImage from "../components/fullWidthImage"
import TwoColumnBlurbSection from "../components/twoColumnBlurbsSection"
import TwoColumnImages from "../components/twoColumnImages"
import ServiceSection from "../components/serviceSection"
import FullWidthText from "../components/fullWidthText"
import SectionDivider from "../components/sectionDivider"
import OverlayImage from "../components/overlayImage"
import HeroAndTextSection from "../components/heroAndTextSection"
import LeadershipTeam from "../components/leadershipTeam"
import MediaCenter from "../components/mediaCenter"
import { standardizePrismicType } from "../utils/standardizePrismicType"

export const query = graphql`
  query GeneralQuery {
    prismic {
      allGenerals {
        edges {
          node {
            _meta {
              uid
            }
            body {
              ... on PRISMIC_GeneralBodyFull_width_image {
                primary {
                  image
                }
              }
              ... on PRISMIC_GeneralBodyService_info {
                primary {
                  call_to_action_button_text
                  service_background_color
                  service_description
                  service_title
                  title_side
                  contact_form_hide_number_of_vehicles
                  contact_form_hide_company
                  contact_form_hide_advanced_fields
                  lead_source
                }
                fields {
                  united_road_advantage_description
                  united_road_advantage_icon
                }
              }
              ... on PRISMIC_GeneralBody50_50_images {
                primary {
                  left_image
                  right_image
                }
              }
              ... on PRISMIC_GeneralBodyHero_section {
                primary {
                  hero_description
                  hero_image
                  page_title
                  page_tagline
                  salesforce_lead_source
                  contact_form_hide_number_of_vehicles
                  contact_form_hide_company
                  contact_form_hide_advanced_fields
                  call_to_action_button_text
                  subaction_destination_url {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  subaction_text
                }
              }
              ... on PRISMIC_GeneralBodyTwo_column_w__icons2 {
                primary {
                  call_to_action_button_text
                  contact_form_hide_number_of_vehicles
                  contact_form_hide_company
                  contact_form_hide_advanced_fields
                  lead_source
                }
                fields {
                  service_column
                  service_icon
                  service_item
                }
              }
              ... on PRISMIC_GeneralBodyFull_width_text {
                primary {
                  rich_text
                }
              }
              ... on PRISMIC_GeneralBodyContact_us {
                primary {
                  address
                  address_icon
                  phone
                  phone_icon
                  lead_source
                }
              }
              ... on PRISMIC_GeneralBodyImage_and_overlay {
                primary {
                  page_top_caption
                  main_caption
                  background_image
                }
              }
              ... on PRISMIC_GeneralBodyGrey_border {
                type
              }
              ... on PRISMIC_GeneralBodyExecutive_bios {
                fields {
                  position
                  picture
                  name
                  bio
                }
              }
              ... on PRISMIC_GeneralBodyHero_and_text {
                primary {
                  hero_text
                  tagline
                  title
                }
              }
              ... on PRISMIC_GeneralBodyMedia_center {
                fields {
                  article_date
                  article_title
                  article_link_destination {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                    ... on PRISMIC__ImageLink {
                      _linkType
                      url
                    }
                  }
                }
                primary {
                  headline
                  subhead
                }
              }
              ... on PRISMIC_GeneralBodyHalf_and_half_text {
                primary {
                  left_text
                  right_text
                  background
                }
              }
              ... on PRISMIC_GeneralBodyHero__icons___image {
                primary {
                  hero_image
                  left_text
                }
                fields {
                  icon
                  text
                }
              }
            }
            general_meta_description
            general_meta_title
            page_title
          }
        }
      }
    }
  }
`

const General = ({ data }) => {
  const generalPages = data.prismic.allGenerals.edges
  if (typeof window === `undefined`) return null
  const page = generalPages.find((page) => {
    return page.node._meta.uid === window.location.pathname.split("/")[1]
  })
  if (!page) return null
  const prismicSections = page.node.body.map((section) =>
    standardizePrismicType(section),
  )
  if (!prismicSections) return null

  const metaTitle =
    page.node.general_meta_title && page.node.general_meta_title[0]?.text
  const metaDescription =
    page.node.general_meta_description &&
    page.node.general_meta_description[0]?.text
  const bottomBorder = page.node._meta.uid !== "contact"

  return (
    <Layout activePage={page.node._meta.uid} bottomBorder={bottomBorder}>
      <SEO metaTitle={metaTitle} metaDescription={metaDescription} />
      <div>
        {prismicSections.map((prismicSection) => {
          let template
          switch (prismicSection.__typename) {
            case "PRISMIC_GeneralBodyFull_width_image":
              template = (
                <FullWidthImage
                  src={prismicSection.primary.image.url}
                  alt={prismicSection.primary.image.alt}
                />
              )
              break
            case "PRISMIC_GeneralBodyService_info":
              const title =
                prismicSection.primary.service_title[0] &&
                prismicSection.primary.service_title[0].text
              template = (
                <ServiceSection
                  title={title}
                  description={prismicSection.primary.service_description}
                  backgroundColor={
                    prismicSection.primary.service_background_color
                  }
                  titleSide={prismicSection.primary.title_side}
                  callToActionText={
                    prismicSection.primary.call_to_action_button_text[0] &&
                    prismicSection.primary.call_to_action_button_text[0].text
                  }
                  leadSource={
                    prismicSection.primary.lead_source &&
                    prismicSection.primary.lead_source[0]?.text
                  }
                  callToActionBackgroundColor={
                    prismicSection.primary.call_to_action_button_background
                  }
                  serviceTagline={prismicSection.primary.service_caption}
                  serviceBlurbs={prismicSection.fields}
                  activeSection={null}
                  setActiveSection={() => null}
                  hideAdvanced={
                    prismicSection.primary.contact_form_hide_advanced_fields
                  }
                  hideCompany={prismicSection.primary.contact_form_hide_company}
                  hideNumberOfVehicles={
                    prismicSection.primary.contact_form_hide_number_of_vehicles
                  }
                />
              )
              break
            case "PRISMIC_GeneralBodyHero_section":
              template = (
                <HeroSectionWithImage heroData={prismicSection.primary} />
              )
              break
            case "PRISMIC_GeneralBodyTwo_column_w__icons2":
              template = (
                <TwoColumnBlurbSection
                  blurbsData={prismicSection.fields}
                  callToActionData={prismicSection.primary}
                />
              )
              break
            case "PRISMIC_GeneralBodyGrey_border":
              template = <SectionDivider />
              break
            case "PRISMIC_GeneralBody50_50_images":
              template = (
                <TwoColumnImages
                  leftImageData={prismicSection.primary.left_image}
                  rightImageData={prismicSection.primary.right_image}
                />
              )
              break
            case "PRISMIC_GeneralBodyFull_width_text":
              template = (
                <FullWidthText richText={prismicSection.primary.rich_text} />
              )
              break
            case "PRISMIC_GeneralBodyContact_us":
              template = <ContactSection contactData={prismicSection.primary} />
              break
            case "PRISMIC_GeneralBodyImage_and_overlay":
              template = <OverlayImage componentData={prismicSection.primary} />
              break
            case "PRISMIC_GeneralBodyExecutive_bios":
              template = <LeadershipTeam teamData={prismicSection.fields} />
              break
            case "PRISMIC_GeneralBodyHero_and_text":
              template = (
                <HeroAndTextSection componentData={prismicSection.primary} />
              )
              break
            case "PRISMIC_GeneralBodyMedia_center":
              template = (
                <MediaCenter
                  articlesData={prismicSection.fields}
                  componentData={prismicSection.primary}
                />
              )
              break
            case "PRISMIC_GeneralBodyHalf_and_half_text":
              template = (
                <HalfAndHalfText
                  rightText={prismicSection.primary.right_text}
                  leftText={prismicSection.primary.left_text}
                  background={prismicSection.primary.background}
                />
              )
              break
            case "PRISMIC_GeneralBodyHero__icons___image":
              template = (
                <HeroWithIconsAndImage
                  iconFields={prismicSection.fields}
                  generalFields={prismicSection.primary}
                />
              )
              break
            default:
              template = null
          }
          return template
        })}
      </div>
    </Layout>
  )
}

export default General
