import React from "react"
import HeroSection from "./heroSection"
import FullWidthText from "./fullWidthText"
import styles from "./stylesheets/heroAndTextSection.module.scss"

export default function HeroAndTextSection({ componentData }) {
  const title = componentData.title && componentData.title[0]?.text
  const tagline = componentData.tagline && componentData.tagline[0]?.text

  return (
    <div className={styles.topLevelContainer}>
      <section className="row no-gutters px-lg-5">
        <div className="col-12 col-lg-6 pl-md-5 pl-lg-0 mt-md-4 pr-md-3">
          <HeroSection
            title={tagline}
            description={null}
            pageType={title}
            hasJobListingsLink={false}
            noPadding
          />
        </div>
        <div className="col-12 col-lg-6 px-0 pb-3 mt-md-5">
          <div className="mt-md-4 pt-2">
            <FullWidthText richText={componentData.hero_text} noPadding />
          </div>
        </div>
      </section>
    </div>
  )
}
