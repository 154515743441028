import React from "react"
import Imgix from "react-imgix"
import styles from "./stylesheets/overlayImage.module.scss"
import cx from "classnames"

export default function OverlayImage({ componentData }) {
  const image = componentData.background_image
  const mainCaption =
    componentData.main_caption && componentData.main_caption[0]?.text
  const subCaption =
    componentData.page_top_caption && componentData.page_top_caption[0]?.text

  return (
    <div>
      <div className={styles.overlayContainer}>
        <Imgix
          src={image.url}
          alt={image.alt}
          className={cx(styles.backgroundImage, "d-block w-100")}
          sizes="100vw"
        />
        <div
          className={cx(
            styles.captionContainer,
            "d-flex flex-column pl-4 pl-md-5 text-white",
          )}
        >
          <div className={cx(styles.subCaption, "d-none d-md-flex pt-4")}>
            {subCaption}
          </div>
          <div className={cx(styles.mainCaption, "d-flex mt-5 pt-3 pt-md-0")}>
            {mainCaption}
          </div>
        </div>
      </div>
    </div>
  )
}
